import connectionImg from '~/assets/images/offline/connection.svg'
import MainLayout from '~/components/layouts/MainLayout'

import * as containerStyles from './Offline.module.scss'

const Offline = () => (
  <MainLayout>
    <div className={containerStyles.offlinePage}>
      <h1 className={containerStyles.offlinePage__title}>
        It looks like you’ve lost your <span>Internet connection</span>
      </h1>
      <img
        src={connectionImg}
        className={containerStyles.offlinePage__image}
        alt="Internet connection error | Codica"
        title="Internet connection error"
        loading="lazy"
      />
      <div className={containerStyles.offlinePage__text}>
        <p>But you probably already knew that.</p>
        <p>
          Have a nice day either way. Maybe you can go play outside,
          <span className="d-block" key="spanKey" /> some fresh air could do you
          good.
        </p>
      </div>
    </div>
  </MainLayout>
)

export default Offline
